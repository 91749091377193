import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

import { PagesComponent } from "./pages/pages.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { WebsiteLoginComponent } from "./pages/website-login/website-login.component";
import { CommingFromComponent } from "./pages/comming-from/comming-from.component";
import { UserAuthGuard } from "./shared/auth/guard/user-auth.guard";

export const routes: Routes = [
    {
        path: "",
        component: PagesComponent,
        children: [
            {
                path: "",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/home/home.module").then(
                        (m) => m.HomeModule
                    ),
            },
            {
                path: "account",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/account/account.module").then(
                        (m) => m.AccountModule
                    ),
                data: { breadcrumb: "Account Settings" },
            },
            {
                path: "compare",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/compare/compare.module").then(
                        (m) => m.CompareModule
                    ),
                data: { breadcrumb: "Compare" },
            },
            {
                path: "wishlist",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/wishlist/wishlist.module").then(
                        (m) => m.WishlistModule
                    ),
                data: { breadcrumb: "Wishlist" },
            },
            {
                path: "cart",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/cart/cart.module").then(
                        (m) => m.CartModule
                    ),
                data: { breadcrumb: "Cart" },
            },
            {
                path: "checkout",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/checkout/checkout.module").then(
                        (m) => m.CheckoutModule
                    ),
                data: { breadcrumb: "Checkout" },
            },
            {
                path: "contact",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/contact/contact.module").then(
                        (m) => m.ContactModule
                    ),
                data: { breadcrumb: "Contact" },
            },
            {
                path: "brands",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/brands/brands.module").then(
                        (m) => m.BrandsModule
                    ),
                data: { breadcrumb: "Brands" },
            },
            {
                path: "products",
                canActivate: [UserAuthGuard],
                loadChildren: () =>
                    import("./pages/products/products.module").then(
                        (m) => m.ProductsModule
                    ),
                data: { breadcrumb: "All Products" },
            },
        ],
    },
    {
        path: "sign-in",
        loadChildren: () =>
            import("./pages/sign-in/sign-in.module").then(
                (m) => m.SignInModule
            ),
        data: { breadcrumb: "Sign In " },
    },
    {
        path: "website-login/:token",
        component: WebsiteLoginComponent,
    },
    {
        path: "landing",
        loadChildren: () =>
            import("./landing/landing.module").then((m) => m.LandingModule),
    },
    {
        path: "admin",
        loadChildren: () =>
            import("./admin/admin.module").then((m) => m.AdminModule),
    },
    { path: "commingFrom", component: CommingFromComponent },
    { path: "**", component: NotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: "legacy",
            initialNavigation: "enabled", // for one load page, without reload
            // useHash: true
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
