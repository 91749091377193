import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  categories = [];
  constructor(private router: Router, public appService: AppService) { }

  ngOnInit() {
    this.getCategories();
  }
  async getCategories() {
    let res = await this.appService.CallApi("/product/category/parent/child/list", {});
    if (res.status) {
      if (res.data.responseCode == 200) {         
        this.categories = res.data.responseData;
      }
    } else {
      this.appService.axiosError(res.data)
    }
  }
  public onChangeCategory(id, event) {
    localStorage.setItem('category_id', id);
    if (event.target) { 
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate(['products/'+ event.target.innerText.toLowerCase()]);
      });
    //  this.router.navigate(['/products', event.target.innerText.toLowerCase()]);
    }
  }
  openMegaMenu() {
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

}
