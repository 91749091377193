import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Data, AppService } from '../../app.service';
import { Product } from '../../app.models';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  @Input() product: Product;
  @Input() type: string;
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Output() onQuantityChange: EventEmitter<any> = new EventEmitter<any>();
  public count: number = 1;
  public align = 'center center';
  constructor( public router: Router,public appService: AppService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    if (this.product) {
      if (this.product.cartCount > 0) {
        this.count = this.product.cartCount;
      }
    }
    if (localStorage.getItem("unique_id") == undefined) {
      localStorage.setItem("unique_id", uuidv4())
    }
    this.layoutAlign();
  }

  public layoutAlign() {
    if (this.type == 'all') {
      this.align = 'space-between center';
    }
    else if (this.type == 'wish') {
      this.align = 'start center';
    }
    else {
      this.align = 'center center';
    }
  }

  public increment(count) {
    this.count=count;
    if (this.count < this.product.availibilityCount) {
      this.count++;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.newPrice
      }
     this.appService.Data.totalPrice =this.appService.Data.totalPrice+this.product.newPrice;
      // alert(this.appService.Data.totalPrice)
      this.product.quantity=this.count;
      this.changeQuantity(obj);
    }
    else {
      this.snackBar.open('You can not choose more items than available. In stock ' + this.count + ' items.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }
  wishlistcount = 0;
  public decrement(count) {
    this.count=count;
    if (this.count > 1) {
      this.count--;
      let obj = {
        productId: this.product.id,
        soldQuantity: this.count,
        total: this.count * this.product.newPrice
      }
       this.appService.Data.totalPrice =this.appService.Data.totalPrice-this.product.newPrice;
      // alert(this.appService.Data.totalPrice)
      this.product.quantity=this.count;
      this.changeQuantity(obj);
    }
  }
  

  public addToCompare(product: Product) {
    this.appService.addToCompare(product);
  }

  async addToWishList(product) {

    let res = await this.appService.CallApiPost("/wishlist/add", { "productId": product._id });
    if (res.status) {
      if (res.data.responseCode == 200) {
        this.appService.alert(1, "Product added in wishlist");
        this.wishlistcount ++;
        this.appService.Data.wishlistcount ++;  
            var array = this.appService.Data.productsList;
            if (array.length > 0) {
              let index = array.indexOf(product);
              this.appService.Data.productsList[index].productExistInWishlist = true;
            } else {
              product.productExistInWishlist = true;
            }
          
        
      } else {
        this.appService.alert(0, res.data.responseMessage);
      }
    } else {     
      this.appService.axiosError(res.data)
    }
  }
  async removeToWishList(product) {
    var array = this.appService.Data.productsList;
    if (array.length > 0) {
      let index = array.indexOf(product);
      this.appService.Data.productsList[index].productExistInWishlist = false;
    } else {
      product.productExistInWishlist = false;
    }
    let res = await this.appService.DeleteApi("/wishlist/delete/"+product._id, {  });
    if (res.status) {
      if (res.data.responseCode == 200) {
        this.appService.alert(1, "Product remove from wishlist");
        this.wishlistcount --;
        this.appService.Data.wishlistcount --;  
      } else {
        this.appService.alert(0, res.data.responseMessage);
      }
    }     
  }
  async CartList() {
    let res = await this.appService.CallApi("/cart/list", { "code": localStorage.getItem("unique_id") });
    if (res.status) {
      if (res.data.responseCode == 200) {
        this.appService.Data.cartList = res.data.responseData.list;

      }
    } else {
      this.appService.axiosError(res.data)
    }
  }

  async addToCart(product) {

    // let currentProduct = this.appService.Data.cartList.filter(item => item.id == product.id)[0];
    // if (currentProduct) {
    //   if ((currentProduct.cartCount + this.count) <= this.product.availibilityCount) {
    //     product.cartCount = currentProduct.cartCount + this.count;

    let res = await this.appService.CallApiPost("/cart/add", { "productId": product._id, "quantity": this.count, "code": localStorage.getItem("unique_id") });
    if (res.status) {
      if (res.data.responseCode == 200) {
        this.CartList();
        this.appService.alert(1, 'The product ' + product.name + ' has been added to cart.');
      } else {
        this.appService.alert(1, res.data.responseMessage);

      }
    } else {
      this.appService.axiosError(res.data)
    }
    //   }
    //   else {
    //     this.snackBar.open('You can not add more items than available. In stock ' + this.product.availibilityCount + ' items and you already added ' + currentProduct.cartCount + ' item to your cart', '×', { panelClass: 'error', verticalPosition: 'top', duration: 5000 });
    //     return false;
    //   }
    // }
    // else {
    //   product.cartCount = this.count;
    // }
    //this.appService.addToCart(product);
  }

  public openProductDialog(event) {
    this.onOpenProductDialog.emit(event);
  }

  public changeQuantity(value) {
    this.onQuantityChange.emit(value);
  }

}