import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import { ApiService } from 'src/app/shared/api/api.service';
import { User } from "src/app/shared/interfaces/user.interface";
import { InteractionService } from "src/app/shared/services/interaction.service";

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = ['USD', 'EUR'];
  public currency: any;
  public userName: String;
  public userEmail: String;
  public userImage: any;

  public settings: Settings;
  userData = {
		personal_info: { first_name: "", last_name: "", profile_image: "" },
		user_id: "",
	};
  constructor(public appSettings: AppSettings,  public authService: AuthService,public appService: AppService, public router: Router
    , public translateService: TranslateService,
    public interactionService: InteractionService,
    public apiService: ApiService
    ) {
    this.settings = this.appSettings.settings;
    
  }

  wishlistcount = 0;
  ngOnInit() {
    this.loadUserData();
    this.WishList();
    this.currency = this.currencies[0];
  }

  public changeCurrency(currency) {
    this.currency = currency;
  }

  loadUserData() {
		this.apiService.ApiGET("").subscribe((res: any) => {
			if (res.status) {
				const result = res.result;
				const user: User = {
					...result,
					role_name: res.role,
					role_weight: res.role_weight,
				};

				this.interactionService.setUserInfo(user);
			}

			this.userData = res.result;
		});
	}
  
  async WishList() {
   if( this.authService.tokenGet()!=null){
    let res = await this.appService.CallApi("/wishlist/count", {});
    if (res.status) {
      if (res.data.responseCode == 200) {
        this.wishlistcount = res.data.responseData.count;
       
      }
    } else {
      this.appService.axiosError(res.data)
    }
  }
  }
  public changeLang(lang: string) {
    this.translateService.use(lang);
  }

  public getLangText(lang) {
    if (lang == 'de') {
      return 'German';
    }
    else if (lang == 'fr') {
      return 'French';
    }
    else if (lang == 'ru') {
      return 'Russian';
    }
    else if (lang == 'tr') {
      return 'Turkish';
    }
    else {
      return 'English';
    }
  }
  logout(){
    localStorage.clear();
    localStorage.removeItem('auth_token')
    this.router.navigate(['sign-in'])
  }

}
