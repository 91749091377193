import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { tap } from "rxjs/operators";
// import {AppService} from '../../app.service'
@Injectable()
export class AuthService {
	public user: any;
	public token: string;
	// private serverUrl = environment.serverUrl;
	private serverUrl = "";

	constructor(private http: HttpClient, public router: Router) {}

	loginUser(email: string, password: string) {
		// const url = `${this.serverUrl}` + '/login';
		// const httpOptions = {
		//   headers: new HttpHeaders({
		//     'Content-Type': 'application/json'
		//   })
		// };
		// return this.http.post(url, { 'email': email, 'password': password }, httpOptions)
		//   .pipe(
		//     tap((res: any) => {
		//       this.token = res.token;
		//       localStorage.setItem('user_name', res.user_name);
		//       localStorage.setItem('user_id', res.user_id);
		//       if (this.token.length) {
		//         localStorage.setItem('thunder_token', this.token);
		//       } else {
		//         localStorage.setItem('thunder_token', '');
		//       }
		//     })
		//   );
	}

	forgotPwd(email: string) {
		// const url = `${this.serverUrl}` + '/forgot-pwd';
		// const httpOptions = {
		//   headers: new HttpHeaders({
		//     'Content-Type': 'application/json'
		//   })
		// };
		// return this.http.post(url, { 'email': email }, httpOptions);
	}

	resetPwd(email: string, token: string, password: string) {
		// const url = `${this.serverUrl}` + '/reset-pwd';
		// const httpOptions = {
		//   headers: new HttpHeaders({
		//     'Content-Type': 'application/json'
		//   })
		// };
		// return this.http.post(url, { email: email, token: token, password: password }, httpOptions);
	}

	logout() {
		this.tokenRemove();
		this.router.navigate(["/sign-in"]);
	}

	makeLogin(args) {
				localStorage.setItem("auth_token", args.auth_token);
	}

	isAuthenticated() {
			if (this.tokenGet() && this.tokenGet() != undefined) return true;
			else return false;		
	}

	tokenGet() {
		return localStorage.getItem("auth_token");
	}

	tokenRemove() {
		localStorage.removeItem("auth_token");
	}
}
