<div *ngIf="products" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">
        <div *ngFor="let product of products" class="swiper-slide">
            <mat-card class="product-item">
                <mat-chip-list *ngIf="product.discount">
                    <mat-chip color="warn" selected="true">{{product.discount}}% OFF</mat-chip>
                </mat-chip-list>
                <a [routerLink]="['/products', product._id, product.name]" class="image-link">

                    <img *ngIf="product.image!=null" src="{{product.image}}" alt="">
                    <img *ngIf="product.image==null" src="assets/images/user.jpg" alt="">
                    <!-- <div class="swiper-lazy-preloader"></div> -->
                </a>
                <h4 class="category text-muted">{{ ( appService.Data.categories | filterById : product.categoryId )?.name }}</h4>
                <a [routerLink]="['/products', product._id, product.name]" class="title text-truncate">
                    {{product.name}}
                </a>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="prices">
                    <div fxLayout="column" fxLayoutAlign="center start">
                        <p class="old-price text-muted"><span *ngIf="product.oldPrice">${{product.oldPrice | number : '1.2-2'}}</span></p>
                        <p class="new-price">${{product.newPrice | number : '1.2-2'}}</p>
                    </div>
                    <app-rating [ratingsCount]="product.totalRating" [ratingsValue]="product.rating" [direction]="'column'"></app-rating>
                </div>
                <div class="divider mt-2"></div>
                <div class="icons">
                    <app-controls [product]="product" (onOpenProductDialog)="openProductDialog(product)"></app-controls>
                </div>
            </mat-card>
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>