import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError, Observable, Subject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Category, Product } from "./app.models";
import { environment } from "src/environments/environment";
import { AuthService } from "./shared/auth/auth.service";
import { Router } from "@angular/router";
import axios from "axios";

export class Data {
    constructor(
        public categories: Category[],
        public compareList: Product[],
        public wishList: [],
        public cartList: [],
        public productsList: Product[],
        public totalPrice: number,
        public wishlistcount: number,
        public totalCartCount: number
    ) {}
}

@Injectable()
export class AppService {
    public Data = new Data(
        [], // categories
        [], // wishList
        [], // compareList
        [], // productsList
        [], // cartList
        null, //totalPrice,
        0,
        0 //totalCartCount
    );
    public url = environment.url + "/assets/data/";
    public token;
    activeChat = new Subject<any>();
    constructor(
        public http: HttpClient,
        public snackBar: MatSnackBar,
        public authService: AuthService,
        public router: Router
    ) {
        this.token = this.authService.tokenGet();
    }
    public setActiveChat(data){
      this.activeChat.next(data)
    }
    public alert(status, message) {
        status = status == 1 ? "success" : "error";
        this.snackBar.open(message, "×", {
            panelClass: [status],
            verticalPosition: "top",
            duration: 3000,
        });
    }

    public getUrl(postFix) {
        return environment.apiUrl + "/ecommerce" + postFix;
    }
    public getCommonUrl(postFix) {
        return environment.apiUrl + postFix;
    }
    public getToken() {
        
        //return this.token;
        return this.authService.tokenGet();
    }

    //////////////////// AXIOS SERVICES ////////////////////
    public axiosCreate(args = null) {
        //this.token = this.authService.tokenGet();
        let headers = {
            Authorization: "Bearer " + this.getToken(),
        };
        if (args != null) {
            headers = Object.assign(headers, args);
        }
        return axios.create({ headers: headers });
    }
    public axiosError(error) {
        console.error("AXIOS ERROR !!", error);
        if (
            typeof error.response.status != "undefined" &&
            error.response.status == 401
        ) {
            this.authService.tokenRemove();
            this.alert(0, "You are not logged in, please login to continue.");
            this.router.navigate(["/"]);
        } else if (typeof error.response.data.responseMessage != "undefined") {
            this.alert(0, error.response.data.responseMessage);
        } else if (typeof error.response.responseMessage != "undefined") {
            this.alert(0, error.response.responseMessage);
        } else if (
            typeof error.response.data.responseData != "undefined" &&
            error.response.data.responseData.length
        ) {
            let errs = [];
            for (let k in error.response.data.responseData) {
                errs.push(error.response.data.responseData[k].msg);
            }
            this.alert(0, errs.join(", "));
        }
    }
    public axiosRes(status, data) {
        return { status: status, data: data };
    }
    public axiosCommonGet(postFix, params) {
        return this.axiosCreate().get(this.getCommonUrl(postFix), {
            params: params,
        });
    }
    public axiosGet(postFix, params) {
        return this.axiosCreate().get(this.getUrl(postFix), { params: params });
    }
    public axiosCommonPost(postFix, params) {
        return this.axiosCreate().post(environment.apiUrl + postFix, params);
    }
    public axiosPost(postFix, params) {
        return this.axiosCreate().post(this.getUrl(postFix), params);
    }
    public loginPost(postFix, params) {
        return this.axiosCreate().post(environment.apiUrl + postFix, params);
    }
    public axiosPut(postFix, params) {
        return this.axiosCreate().put(this.getUrl(postFix), params);
    }
    public axiosDelete(postFix, params) {
        return this.axiosCreate().delete(this.getUrl(postFix), {
            data: params,
        });
    }

    //////////////////// Banner SERVICES ////////////////////
    public async bannerList(params: any) {
        try {
            let res = await this.axiosGet("/admin/banner/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async bannerAdd(single: any) {
        try {
            let res = await this.axiosCreate({
                "Content-Type": "multipart/form-data",
            }).post(this.getUrl("/admin/banner/add"), single);
            // this.apiHelper.apiPOST("/admin/banner/add",formData).subscribe((res:any)=>{

            // })
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async bannerUpdate(single: any, id: string) {
        try {

            let res = await this.axiosPost(
                "/admin/banner/update/" + id,
                single
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async bannerRemove(id: any) {
        try {
            let res = await this.axiosDelete("/admin/banner/delete/" + id, {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    //////////////////// CATEGORIES SERVICES ////////////////////
    public async categoryList(params: any) {
        try {
            let res = await this.axiosGet("/admin/category/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async categoryParentList() {
        try {
            let res = await this.axiosGet("/admin/category/parent/list", {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async categoryParentChildList() {
        try {
            let res = await this.axiosGet(
                "/admin/category/parent/child/list",
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async categoryAdd(single: any) {
        try {
            let res = await this.axiosPost("/admin/category/add", single);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async categoryUpdate(single: any) {
        try {

            let res = await this.axiosPost(
                "/admin/category/update/" + single.id,
                single
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async categoryRemove(id: any) {
        try {
            let res = await this.axiosDelete(
                "/admin/category/delete/" + id,
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    //////////////////// PRODUCT SERVICES ////////////////////
    public async productAdd(single: any) {
        try {
            // for (let k in single) {
            //   if (k == "images") {
            //     for (let imgK in single[k]) {
            //       if (single[k][imgK].file != undefined) {
            //         formData.append(k, single[k][imgK].file);
            //       }
            //     }
            //   } else {
            //     formData.append(k, single[k]);
            //   }
            // }
            let res = await this.axiosCreate({
                "Content-Type": "multipart/form-data",
            }).post(this.getUrl("/admin/product/add"), single);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async productUpdate(single: any, id: any) {
        try {
            // let formData = new FormData();
            // for (let k in single) {
            //   if (k == "images") {
            //     for (let imgK in single[k]) {
            //       if (single[k][imgK].file != undefined) {
            //         formData.append(k, single[k][imgK].file);
            //       }
            //     }
            //   } else {
            //     formData.append(k, single[k]);
            //   }
            // }
            let res = await this.axiosCreate({
                "Content-Type": "multipart/form-data",
            }).post(this.getUrl("/admin/product/update/" + id), single);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async productList(params: any) {
        try {
            let res = await this.axiosGet("/admin/product/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async productGet(id: any) {
        try {
            let res = await this.axiosGet("/admin/product/edit", {
                productId: id,
            });
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async productImageRemove(id: any) {
        try {
            let res = await this.axiosPost("/admin/product/image/delete", {
                imageId: id,
            });
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async productRemove(id: any) {
        try {
            let res = await this.axiosDelete("/admin/product/delete/" + id, {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async productDetails(id: any) {
        try {
            let res = await this.axiosGet("/admin/product/details/" + id, {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async productDropdownList() {
        try {
            let res = await this.axiosGet("/admin/product/name/list", {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    //////////////////// REVIEWS SERVICES ////////////////////
    public async reviewList(params: any) {
        try {
            let res = await this.axiosGet("/admin/review/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async reviewRemove(id: any) {
        try {
            let res = await this.axiosDelete("/admin/review/delete/" + id, {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async reviewStatusUpdate(id: any, params: any) {
        try {
            let res = await this.axiosCreate({
                "Content-Type": "multipart/form-data",
            }).post(this.getUrl("/admin/review/status/review/" + id), params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    //////////////////// COUPONS SERVICES ////////////////////
    public async couponList(params: any) {
        try {
            let res = await this.axiosGet("/admin/coupon/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async couponGet(id: any) {
        try {
            let res = await this.axiosGet("/admin/coupon/edit", {
                couponId: id,
            });
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async couponAdd(single: any) {
        try {
            let res = await this.axiosPost("/admin/coupon/add", single);
            return this.axiosRes(1, res.data);
        } catch (error) {
            console.log("error", error);

            return this.axiosRes(0, error);
        }
    }
    public async couponUpdate(single: any) {
        try {
            let res = await this.axiosPut(
                "/admin/coupon/update/" + single.id,
                single
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async couponRemove(id: any) {
        try {
            let res = await this.axiosDelete("/admin/coupon/delete/" + id, {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    //////////////////// USERS SERVICES ////////////////////
    public async userList(params: any) {
        try {
            let res = await this.axiosGet("/admin/users/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async userLogin(params: any) {
        try {
            let res = await this.loginPost("/login", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            if (error.response.data.responseCode == 2033) {
                //   this.toastr.error(error.response.data.responseMessage, "Error");
            }
            return this.axiosRes(0, error);
        }
    }

    //////////////////// CUSTOMER SERVICES ////////////////////
    public async customerList(params: any) {
        try {
            let res = await this.axiosGet("/admin/customer/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    //////////////////// ORDER SERVICES ////////////////////
    public async orderList(params: any) {
        try {
            let res = await this.axiosGet("/admin/order/list", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async orderList_Notification(params: any) {
        try {
            let res = await this.axiosGet("/admin/order/listNot", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async orderDetail(id: any) {
        try {
            let res = await this.axiosGet("/admin/order/detail/" + id, {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async orderUpdate(id: any, single: any) {
        try {
            let res = await this.axiosPost("/admin/order/update/" + id, single);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    //////////////////// TRANSACTIONS SERVICES ////////////////////
    public async transactionList(params: any) {
        try {
            let res = await this.axiosGet(
                "/admin/order/transactions/list",
                params
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    public async loadUserPermissions(params: any) {
        try {
            let res = await this.axiosGet("/admin/role/manage", params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // chat services
    public async getChatUsers() {
        try {
            let res = await this.axiosCommonGet("/chat/getUsers", {});
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async getRoomId(data) {
        try {
            let res = await this.axiosCommonPost("/chat/getRoomId", data);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    async getChatHistory(data) {
        try {
            let res = await this.axiosCommonGet(
                "/chat/getChatHistory?room=" + data,
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Verify Token
    public tokenVerify(): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("token", this.getToken());
        return this.http.get(this.getUrl("/token/verify"), {
            params: queryParams,
        });
    }

    public getCategories(): Observable<Category[]> {
        return this.http.get<Category[]>(this.url + "categories.json");
    }

    // public getProducts(type): Observable<Product[]> {
    //     return this.http.get<Product[]>(this.url + type + '-products.json');
    // }

    // public getProductById(id): Observable<Product> {
    //     return this.http.get<Product>(this.url + 'product-' + id + '.json');
    // }

    public getBanners(): Observable<any[]> {
        return this.http.get<any[]>(this.url + "banners.json");
    }

    public addToCompare(product: Product) {
        let message, status;
        if (this.Data.compareList.filter((item) => item.id == product.id)[0]) {
            message =
                "The product " +
                product.name +
                " already added to comparison list.";
            status = "error";
        } else {
            this.Data.compareList.push(product);
            message =
                "The product " +
                product.name +
                " has been added to comparison list.";
            status = "success";
        }
        this.snackBar.open(message, "×", {
            panelClass: [status],
            verticalPosition: "top",
            duration: 3000,
        });
    }

    // public addToCart(product: Product) {
    //     let message, status;

    //     this.Data.totalPrice = null;
    //     this.Data.totalCartCount = null;

    //     if (this.Data.cartList.filter(item => item.id == product.id)[0]) {
    //         let item = this.Data.cartList.filter(item => item.id == product.id)[0];
    //         item.cartCount = product.cartCount;
    //     }
    //     else {
    //         this.Data.cartList.push(product);
    //     }
    //     this.Data.cartList.forEach(product => {
    //         this.Data.totalPrice = this.Data.totalPrice + (product.cartCount * product.newPrice);
    //         this.Data.totalCartCount = this.Data.totalCartCount + product.cartCount;
    //     });

    //     message = 'The product ' + product.name + ' has been added to cart.';
    //     status = 'success';
    //     this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });
    // }

    public resetProductCartCount(product: Product) {
        product.cartCount = 0;
        let compareProduct = this.Data.compareList.filter(
            (item) => item.id == product.id
        )[0];
        if (compareProduct) {
            compareProduct.cartCount = 0;
        }
    }

    public getCountries() {
        return [
            { name: "Afghanistan", code: "AF" },
            { name: "Aland Islands", code: "AX" },
            { name: "Albania", code: "AL" },
            { name: "Algeria", code: "DZ" },
            { name: "American Samoa", code: "AS" },
            { name: "AndorrA", code: "AD" },
            { name: "Angola", code: "AO" },
            { name: "Anguilla", code: "AI" },
            { name: "Antarctica", code: "AQ" },
            { name: "Antigua and Barbuda", code: "AG" },
            { name: "Argentina", code: "AR" },
            { name: "Armenia", code: "AM" },
            { name: "Aruba", code: "AW" },
            { name: "Australia", code: "AU" },
            { name: "Austria", code: "AT" },
            { name: "Azerbaijan", code: "AZ" },
            { name: "Bahamas", code: "BS" },
            { name: "Bahrain", code: "BH" },
            { name: "Bangladesh", code: "BD" },
            { name: "Barbados", code: "BB" },
            { name: "Belarus", code: "BY" },
            { name: "Belgium", code: "BE" },
            { name: "Belize", code: "BZ" },
            { name: "Benin", code: "BJ" },
            { name: "Bermuda", code: "BM" },
            { name: "Bhutan", code: "BT" },
            { name: "Bolivia", code: "BO" },
            { name: "Bosnia and Herzegovina", code: "BA" },
            { name: "Botswana", code: "BW" },
            { name: "Bouvet Island", code: "BV" },
            { name: "Brazil", code: "BR" },
            { name: "British Indian Ocean Territory", code: "IO" },
            { name: "Brunei Darussalam", code: "BN" },
            { name: "Bulgaria", code: "BG" },
            { name: "Burkina Faso", code: "BF" },
            { name: "Burundi", code: "BI" },
            { name: "Cambodia", code: "KH" },
            { name: "Cameroon", code: "CM" },
            { name: "Canada", code: "CA" },
            { name: "Cape Verde", code: "CV" },
            { name: "Cayman Islands", code: "KY" },
            { name: "Central African Republic", code: "CF" },
            { name: "Chad", code: "TD" },
            { name: "Chile", code: "CL" },
            { name: "China", code: "CN" },
            { name: "Christmas Island", code: "CX" },
            { name: "Cocos (Keeling) Islands", code: "CC" },
            { name: "Colombia", code: "CO" },
            { name: "Comoros", code: "KM" },
            { name: "Congo", code: "CG" },
            { name: "Congo, The Democratic Republic of the", code: "CD" },
            { name: "Cook Islands", code: "CK" },
            { name: "Costa Rica", code: "CR" },
            { name: "Cote D'Ivoire", code: "CI" },
            { name: "Croatia", code: "HR" },
            { name: "Cuba", code: "CU" },
            { name: "Cyprus", code: "CY" },
            { name: "Czech Republic", code: "CZ" },
            { name: "Denmark", code: "DK" },
            { name: "Djibouti", code: "DJ" },
            { name: "Dominica", code: "DM" },
            { name: "Dominican Republic", code: "DO" },
            { name: "Ecuador", code: "EC" },
            { name: "Egypt", code: "EG" },
            { name: "El Salvador", code: "SV" },
            { name: "Equatorial Guinea", code: "GQ" },
            { name: "Eritrea", code: "ER" },
            { name: "Estonia", code: "EE" },
            { name: "Ethiopia", code: "ET" },
            { name: "Falkland Islands (Malvinas)", code: "FK" },
            { name: "Faroe Islands", code: "FO" },
            { name: "Fiji", code: "FJ" },
            { name: "Finland", code: "FI" },
            { name: "France", code: "FR" },
            { name: "French Guiana", code: "GF" },
            { name: "French Polynesia", code: "PF" },
            { name: "French Southern Territories", code: "TF" },
            { name: "Gabon", code: "GA" },
            { name: "Gambia", code: "GM" },
            { name: "Georgia", code: "GE" },
            { name: "Germany", code: "DE" },
            { name: "Ghana", code: "GH" },
            { name: "Gibraltar", code: "GI" },
            { name: "Greece", code: "GR" },
            { name: "Greenland", code: "GL" },
            { name: "Grenada", code: "GD" },
            { name: "Guadeloupe", code: "GP" },
            { name: "Guam", code: "GU" },
            { name: "Guatemala", code: "GT" },
            { name: "Guernsey", code: "GG" },
            { name: "Guinea", code: "GN" },
            { name: "Guinea-Bissau", code: "GW" },
            { name: "Guyana", code: "GY" },
            { name: "Haiti", code: "HT" },
            { name: "Heard Island and Mcdonald Islands", code: "HM" },
            { name: "Holy See (Vatican City State)", code: "VA" },
            { name: "Honduras", code: "HN" },
            { name: "Hong Kong", code: "HK" },
            { name: "Hungary", code: "HU" },
            { name: "Iceland", code: "IS" },
            { name: "India", code: "IN" },
            { name: "Indonesia", code: "ID" },
            { name: "Iran, Islamic Republic Of", code: "IR" },
            { name: "Iraq", code: "IQ" },
            { name: "Ireland", code: "IE" },
            { name: "Isle of Man", code: "IM" },
            { name: "Israel", code: "IL" },
            { name: "Italy", code: "IT" },
            { name: "Jamaica", code: "JM" },
            { name: "Japan", code: "JP" },
            { name: "Jersey", code: "JE" },
            { name: "Jordan", code: "JO" },
            { name: "Kazakhstan", code: "KZ" },
            { name: "Kenya", code: "KE" },
            { name: "Kiribati", code: "KI" },
            { name: "Korea, Democratic People'S Republic of", code: "KP" },
            { name: "Korea, Republic of", code: "KR" },
            { name: "Kuwait", code: "KW" },
            { name: "Kyrgyzstan", code: "KG" },
            { name: "Lao People'S Democratic Republic", code: "LA" },
            { name: "Latvia", code: "LV" },
            { name: "Lebanon", code: "LB" },
            { name: "Lesotho", code: "LS" },
            { name: "Liberia", code: "LR" },
            { name: "Libyan Arab Jamahiriya", code: "LY" },
            { name: "Liechtenstein", code: "LI" },
            { name: "Lithuania", code: "LT" },
            { name: "Luxembourg", code: "LU" },
            { name: "Macao", code: "MO" },
            { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
            { name: "Madagascar", code: "MG" },
            { name: "Malawi", code: "MW" },
            { name: "Malaysia", code: "MY" },
            { name: "Maldives", code: "MV" },
            { name: "Mali", code: "ML" },
            { name: "Malta", code: "MT" },
            { name: "Marshall Islands", code: "MH" },
            { name: "Martinique", code: "MQ" },
            { name: "Mauritania", code: "MR" },
            { name: "Mauritius", code: "MU" },
            { name: "Mayotte", code: "YT" },
            { name: "Mexico", code: "MX" },
            { name: "Micronesia, Federated States of", code: "FM" },
            { name: "Moldova, Republic of", code: "MD" },
            { name: "Monaco", code: "MC" },
            { name: "Mongolia", code: "MN" },
            { name: "Montserrat", code: "MS" },
            { name: "Morocco", code: "MA" },
            { name: "Mozambique", code: "MZ" },
            { name: "Myanmar", code: "MM" },
            { name: "Namibia", code: "NA" },
            { name: "Nauru", code: "NR" },
            { name: "Nepal", code: "NP" },
            { name: "Netherlands", code: "NL" },
            { name: "Netherlands Antilles", code: "AN" },
            { name: "New Caledonia", code: "NC" },
            { name: "New Zealand", code: "NZ" },
            { name: "Nicaragua", code: "NI" },
            { name: "Niger", code: "NE" },
            { name: "Nigeria", code: "NG" },
            { name: "Niue", code: "NU" },
            { name: "Norfolk Island", code: "NF" },
            { name: "Northern Mariana Islands", code: "MP" },
            { name: "Norway", code: "NO" },
            { name: "Oman", code: "OM" },
            { name: "Pakistan", code: "PK" },
            { name: "Palau", code: "PW" },
            { name: "Palestinian Territory, Occupied", code: "PS" },
            { name: "Panama", code: "PA" },
            { name: "Papua New Guinea", code: "PG" },
            { name: "Paraguay", code: "PY" },
            { name: "Peru", code: "PE" },
            { name: "Philippines", code: "PH" },
            { name: "Pitcairn", code: "PN" },
            { name: "Poland", code: "PL" },
            { name: "Portugal", code: "PT" },
            { name: "Puerto Rico", code: "PR" },
            { name: "Qatar", code: "QA" },
            { name: "Reunion", code: "RE" },
            { name: "Romania", code: "RO" },
            { name: "Russian Federation", code: "RU" },
            { name: "RWANDA", code: "RW" },
            { name: "Saint Helena", code: "SH" },
            { name: "Saint Kitts and Nevis", code: "KN" },
            { name: "Saint Lucia", code: "LC" },
            { name: "Saint Pierre and Miquelon", code: "PM" },
            { name: "Saint Vincent and the Grenadines", code: "VC" },
            { name: "Samoa", code: "WS" },
            { name: "San Marino", code: "SM" },
            { name: "Sao Tome and Principe", code: "ST" },
            { name: "Saudi Arabia", code: "SA" },
            { name: "Senegal", code: "SN" },
            { name: "Serbia and Montenegro", code: "CS" },
            { name: "Seychelles", code: "SC" },
            { name: "Sierra Leone", code: "SL" },
            { name: "Singapore", code: "SG" },
            { name: "Slovakia", code: "SK" },
            { name: "Slovenia", code: "SI" },
            { name: "Solomon Islands", code: "SB" },
            { name: "Somalia", code: "SO" },
            { name: "South Africa", code: "ZA" },
            {
                name: "South Georgia and the South Sandwich Islands",
                code: "GS",
            },
            { name: "Spain", code: "ES" },
            { name: "Sri Lanka", code: "LK" },
            { name: "Sudan", code: "SD" },
            { name: "Suriname", code: "SR" },
            { name: "Svalbard and Jan Mayen", code: "SJ" },
            { name: "Swaziland", code: "SZ" },
            { name: "Sweden", code: "SE" },
            { name: "Switzerland", code: "CH" },
            { name: "Syrian Arab Republic", code: "SY" },
            { name: "Taiwan, Province of China", code: "TW" },
            { name: "Tajikistan", code: "TJ" },
            { name: "Tanzania, United Republic of", code: "TZ" },
            { name: "Thailand", code: "TH" },
            { name: "Timor-Leste", code: "TL" },
            { name: "Togo", code: "TG" },
            { name: "Tokelau", code: "TK" },
            { name: "Tonga", code: "TO" },
            { name: "Trinidad and Tobago", code: "TT" },
            { name: "Tunisia", code: "TN" },
            { name: "Turkey", code: "TR" },
            { name: "Turkmenistan", code: "TM" },
            { name: "Turks and Caicos Islands", code: "TC" },
            { name: "Tuvalu", code: "TV" },
            { name: "Uganda", code: "UG" },
            { name: "Ukraine", code: "UA" },
            { name: "United Arab Emirates", code: "AE" },
            { name: "United Kingdom", code: "GB" },
            { name: "United States", code: "US" },
            { name: "United States Minor Outlying Islands", code: "UM" },
            { name: "Uruguay", code: "UY" },
            { name: "Uzbekistan", code: "UZ" },
            { name: "Vanuatu", code: "VU" },
            { name: "Venezuela", code: "VE" },
            { name: "Viet Nam", code: "VN" },
            { name: "Virgin Islands, British", code: "VG" },
            { name: "Virgin Islands, U.S.", code: "VI" },
            { name: "Wallis and Futuna", code: "WF" },
            { name: "Western Sahara", code: "EH" },
            { name: "Yemen", code: "YE" },
            { name: "Zambia", code: "ZM" },
            { name: "Zimbabwe", code: "ZW" },
        ];
    }

    public getMonths() {
        return [
            { value: "01", name: "January" },
            { value: "02", name: "February" },
            { value: "03", name: "March" },
            { value: "04", name: "April" },
            { value: "05", name: "May" },
            { value: "06", name: "June" },
            { value: "07", name: "July" },
            { value: "08", name: "August" },
            { value: "09", name: "September" },
            { value: "10", name: "October" },
            { value: "11", name: "November" },
            { value: "12", name: "December" },
        ];
    }

    public getYears() {
        return [
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
            "25",
            "26",
            "27",
            "28",
            "29",
            "30",
        ];
    }

    public getDeliveryMethods() {
        return [
            {
                value: "free",
                name: "Free Delivery",
                desc: "$0.00 / Delivery in 7 to 14 business Days",
            },
            {
                value: "standard",
                name: "Standard Delivery",
                desc: "$7.99 / Delivery in 5 to 7 business Days",
            },
            {
                value: "express",
                name: "Express Delivery",
                desc: "$29.99 / Delivery in 1 business Days",
            },
        ];
    }

    public async CallApi(api_url, params: any) {
        try {
            let res = await this.axiosGet(api_url, params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async CallApiPost(api_url, params: any) {
        try {
            let res = await this.axiosPost(api_url, params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    public async DeleteApi(api_url, params: any) {
        try {
            let res = await this.axiosDelete(api_url, params);
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }

    public async GetDashboard() {
        try {
            let res = await this.axiosGet(
                "/admin/order/GetDashboard",
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    
    public async GetDashboard_Order() {
        try {
            let res = await this.axiosGet(
                "/admin/order/GetDashboard_Order",
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    
    public async GetDashboard_Likes() {
        try {
            let res = await this.axiosGet(
                "/admin/order/GetDashboard_Likes",
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    
    public async GetDashboard_User() {
        try {
            let res = await this.axiosGet(
                "/admin/order/GetDashboard_User",
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    
    public async GetDashboard_Analytics() {
        try {
            let res = await this.axiosGet(
                "/admin/order/GetDashboard_Analytics",
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
    
    public async GetDashboard_Month() {
        try {
            let res = await this.axiosGet(
                "/admin/order/GetDashboard_Month",
                {}
            );
            return this.axiosRes(1, res.data);
        } catch (error) {
            return this.axiosRes(0, error);
        }
    }
}
