<div class="main-slider">
  <div class="swiper-container h-100" [swiper]="config">
    <div class="swiper-wrapper h-100">
      <div *ngFor="let slide of slides" class="swiper-slide">
        <div [attr.data-background]="slide.image" [style.background-image]="'url(' + slide.image+ ')'" class="slide-item swiper-lazy">
          <div class="swiper-lazy-preloader-white"></div>
          <div class="mask"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="content">
            <h1>{{slide.title}}</h1>
            <h3>{{slide.subTitle}}</h3>
            <!-- <button mat-raised-button color="primary">Shop now</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination white"></div>
    <button mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-mini-fab color="primary" class="swiper-button-next swipe-arrow">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <!-- <div class="swiper-container  h-100" [swiper]="config">
    <div class="swiper-wrapper  h-100">
      <div *ngFor="let slide of slides" class="swiper-slide">
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
          <img [src]="slide.image" alt="image" class="slide">
        </div>
      </div>
    </div>

    <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div>
    <div class="swiper-pagination" [hidden]="config.pagination === false"></div>

    <div class="swiper-button-prev" [hidden]="config.navigation === false"></div>
    <div class="swiper-button-next" [hidden]="config.navigation === false"></div>
  </div> -->
</div>