export class Category {
    constructor(
        public id: number,
        public name: string,
        public hasSubCategory: boolean,
        public parentId: any
    ) {}
}

export class Product {
    constructor(
        public _id: number,
        public productExistInWishlist: boolean,
        public id: number,
        public quantity: number,

        public name: string,
        public categoryName: string,
        public images: Array<any>,
        public image: Array<any>,
        public oldPrice: number,
        public newPrice: number,
        public discount: number,
        public ratingsCount: number,
        public ratingsValue: number,
        public description: string,
        public longDescription: string,
        public availibilityCount: number,
        public cartCount: number,
        public color: Array<string>,
        public size: Array<string>,
        public review: Array<string>,
        public weight: number,
        public additionalInformation: string,
        public rating: number,
        public categoryId: number
    ) {}
}
