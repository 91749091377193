import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../interfaces/user.interface';
import { Permissions } from '../interfaces/permission.interface';

@Injectable({
    providedIn: 'root'
})
export class InteractionService {
    userInfo = new BehaviorSubject<User>(null);
    setUserInfo(user: User) {
        this.userInfo.next(user);
    }

    permissions = new BehaviorSubject<Permissions>(null);
    setPermissions(data: Permissions) {
        this.permissions.next(data);
    }

    academicYears = new BehaviorSubject<any>(null);
    setAcademicYears(years: any) {
        this.academicYears.next(years);
    }

    sessions = new BehaviorSubject<any>(null);
    setSessions(sessions: any) {
        this.sessions.next(sessions);
    }

    classes = new BehaviorSubject<any>(null);
    setClasses(classes: any) {
        this.classes.next(classes);
    }

    holidays = new BehaviorSubject<any>(null);
    setHolidays(holidays: any) {
        this.holidays.next(holidays);
    }
}
