import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { tap } from "rxjs/operators";
import { environment } from "./../../../environments/environment";
import { Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ApiService {
	private token: string;
	private serverUrl = environment.apiUrl;
	constructor(private http: HttpClient, private router: Router) {
		
	}

	

	public ApiGET(endpoint, params = {}) {
		params = params != undefined ? params : {};
		this.token = localStorage.getItem("auth_token");
		const url = `${this.serverUrl}` + "/" + endpoint;
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				authorization: "Bearer " + this.token,
			}),
			params: params,
		};
		console.log(url, httpOptions)
		return this.http.get(url, httpOptions).pipe(
			tap(
				(res: any) => {
					return res;
				},
				(err: any) => {
					if (err.status == 401) {
						this.router.navigate(["/sign-in"]);
					} else if (err.status == 200) {
						return err;
					} else {
						// this.router.navigate(['/serverErr'])
						return null;
					}
				}
			)
		);
	}

}
