import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-website-login',
  templateUrl: './website-login.component.html',
  styleUrls: ['./website-login.component.scss']
})
export class WebsiteLoginComponent implements OnInit {

  public token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let self = this;
      if (params.token) {
        self.authService.makeLogin({
          auth_token: params.token
        });
        setTimeout(() => {
          self.router.navigate(['/']);
        }, 50);
      }
      else {
        self.router.navigate(['/']);
      }
    });
  }

}
