import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(
    private router: Router,
    public authService: AuthService,
    public appService: AppService,
    public snackBar: MatSnackBar

  ) { }
  async canActivate(){
    try{
      let permisions = await this.appService.loadUserPermissions({})
      let weight = permisions.data.responseData.weight;

      let isAuth = this.authService.isAuthenticated();
      if (!isAuth) {
        this.router.navigate(['/sign-in']);
        return true;
      }
      else {
        if(weight<=4)return true;
        else{
          this.router.navigate([''])
          return true
        }
      }
    }catch (err){
      this.router.navigate(['/sign-in']);
      return true;
    }
      
  }
  
}
